import { Button } from "antd"
import { Link } from "gatsby"
import React from "react"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import {
  FontStyle,
  TextColor,
} from "../SharedResources/Typography/typography.service"

import DomeNotFound from "../../images/domeNotFound.svg"
import "./NotFound.scss"

const NotFound: React.FC = () => {
  return (
    <section className="not-found-container">
      <article className="flex flex-col-reverse md:flex-row md:space-x-40 lg:space-x-52 container mx-auto md:mt-20 items-center justify-center px-8">
        <div className="flex flex-col max-w-xs">
          <div className="flex flex-col items-center md:items-start">
            <TextTypography className="text-7xl" fontStyle={FontStyle.BOLD}>
              404
            </TextTypography>
            <TextTypography
              className="text-2xl mt-4"
              fontStyle={FontStyle.BOLD}
            >
              Page not found
            </TextTypography>
            <TextTypography className="mt-4 text-center md:text-left">
              Something went wrong, there isn’t anything at this location now.
              Try finding what you need from our home page.
            </TextTypography>
          </div>

          <Button className="bg-white py-3 px-16 rounded-lg mt-14 h-auto border border-primary">
            <Link to="/">
              <TextTypography
                className="text-base h-full w-full"
                fontStyle={FontStyle.BOLD}
                color={TextColor.PRIMARY}
              >
                Return Home
              </TextTypography>
            </Link>
          </Button>
        </div>
        <img
          src={DomeNotFound}
          alt="Dome Not Found"
          className="mx-auto mb-12 md:mb-0"
        />
      </article>
    </section>
  )
}
export default NotFound
