import React from "react"
import AppFooter from "../components/AppFooter/AppFooter"
// import AppHeader from "../components/AppHeader/AppHeader"
import AppHeader from "../components/V2/AppHeader"
import FaviconHeader from "../components/FaviconHeader/FaviconHeader"
import NotFound from "../components/NotFound/NotFound"
const NotFoundPage: React.FC = () => {
  return (
    <main className="w-full bg-lightBackground">
      <FaviconHeader />
      {/* <AppHeader className="transparent-bg-color" /> */}
      <AppHeader />
      <NotFound />
      <AppFooter />
    </main>
  )
}

export default NotFoundPage
